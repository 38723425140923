<template>
    <div class="columns is-multiline">
        <div class="column is-two-thirds-desktop">
            <div class="field">
                <label class="label">{{ $t('Parcel machine') }}</label>
                <v-select
                    id="machine-location-select"
                    v-model="parcelObject"
                    :options="currentLockers"
                    label="search_key"
                    @search="searchPickupPoints($event)"
                />
                <p class="help">{{ $t('Start typing to search locations') }}</p>
                <input
                    name="bg_boxnow_parcel_locker_id"
                    :value="parcelObject ? parcelObject.locker_id : null"
                    type="text"
                    hidden
                >
                <input
                    name="bg_boxnow_parcel_address"
                    :value="parcelObject ? parcelObject.address : null"
                    type="text"
                    hidden
                >
                <input
                    name="bg_boxnow_parcel_city"
                    :value="parcelObject ? parcelObject.city : null"
                    type="text"
                    hidden
                >
                <input
                    name="bg_boxnow_parcel_post_number"
                    :value="parcelObject ? parcelObject.postal_code : null"
                    type="text"
                    hidden
                >
                <input
                    name="bg_boxnow_parcel_name"
                    :value="parcelObject ? parcelObject.name : null"
                    type="text"
                    hidden
                >
            </div>
        </div>
    </div>
</template>

<script>

import _ from 'lodash';
import axios from 'axios';

export default {
    name:  "BgBoxnowParcelSelect",

    props: {
        selectedId: {
            required: true,
            default: null,
            type: String
        },
        initialLockers: {
            required: true,
            type: Array
        },
    },
    data() {
        return {
            parcelObject: {
                locker_id: null,
                name: null,
                address: null,
                postal_code: null,
                city: null,
                search_key: null,
            },
            currentLockers: [],
        };
    },
    watch: {
        parcelObject(newValue) {
            if (newValue === null) {
                this.searchPickupPoints('');
            }
        }
    },
    created() {
        this.currentLockers = this.initialLockers;
        if (this.selectedId !== null) {
            this.findPickupPoint();
        }
    },
    methods: {
        findPickupPoint: function () {
            this.parcelObject = this.currentLockers.find(element => element.locker_id === parseInt(this.selectedId));
        },
        searchPickupPoints: _.debounce(
            function (event) {
                axios.get('/boxnow/search-parcel-lockers', {
                    params: {
                        search_word: event
                    }
                }).then(response => {
                    this.currentLockers = response.data;
                });
            }, 500)
    },
};
</script>
